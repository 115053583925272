import React, { useEffect, useState, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import {
  Col,
  Container,
  Row
} from 'react-bootstrap'

import { CertificationData, CertificateBlocks } from './CertificateCanvas'
import { fetchRemoesAPI } from './API'

const Direct = (): ReactElement => {
  const { directCode } = useParams()
  const [certData, setCertData] = useState<CertificationData|null>(null)

  useEffect(() => {
    fetchRemoesAPI(undefined, undefined, directCode)
      .then(async (response: Response) => await response.json())
      .then((data: CertificationData) => {
        if (data.status !== 'ok') {
          throw new Error('API response status is not ok')
        }
        setCertData(data)
      })
      .catch((error: any) => {
        console.log(error)
        setCertData(null)
      })
  }, [])

  const certificateBlocks = certData !== null
    ? <CertificateBlocks certData={certData} showDownloadButton={false} showDirectURL={false} />
    : (
      <Row className='justify-content-md-center'>
        <Col lg='8'>
          証明書は公開されていません。
        </Col>
      </Row>
      )

  return (
    <>
      <div className='titleHeader'>
        <h2>デジタル合格証取得ページ</h2>
      </div>
      <Container className='mainContainer'>
        {certificateBlocks}
      </Container>
      <MetaTags>
        <meta name='robots' content='noindex' />
      </MetaTags>
    </>
  )
}

export default Direct
