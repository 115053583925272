import React, { useEffect, ReactElement } from 'react'
import MetaTags from 'react-meta-tags'

import {
  Container
} from 'react-bootstrap'

import { CertificateBlocks } from './CertificateCanvas'

import { useLocation, useNavigate } from 'react-router-dom'

const Certificates = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  const certData = state?.certData ?? null

  useEffect(() => {
    if (certData === null) {
      navigate('/')
    }
  })

  if (certData === null) {
    return <></>
  }

  const certificateBlocks = certData !== null
    ? <CertificateBlocks certData={certData} showDownloadButton showDirectURL />
    : undefined

  return (
    <>
      <div className='titleHeader'>
        <h2>デジタル合格証取得ページ</h2>
      </div>
      <Container className='mainContainer'>
        {certificateBlocks}
      </Container>
      <MetaTags>
        <meta name='robots' content='noindex' />
      </MetaTags>
    </>
  )
}

export default Certificates
