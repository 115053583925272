import React, { forwardRef, useEffect, useRef, useState, ReactElement, useId } from 'react'

import './CertificateCanvas.css'
import MessageModal, { ModalControl } from './MessageModal'

import useImage from 'use-image'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false
/* eslint-disable import/first */
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import FontFaceObserver from 'fontfaceobserver'

import {
  Button,
  Col,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap'

import { jsPDF as JsPDF } from 'jspdf'
/* eslint-enable import/first */

const FONT_GOTHIC = '"Noto Sans JP", sans-serif'
const FONT_MINCHO = '"Noto Serif JP", serif'
const FONT_MONO = '"Noto Sans Mono"'

const FONT_FAMILY_CONVERSION: { [K: string]: string } = {
  MINCHO: FONT_MINCHO,
  GOTHIC: FONT_GOTHIC,
  MONO: FONT_MONO
}

interface Informations {
  [information: string]: string
}

enum LeftRight {
  left = 'left',
  right = 'right'
}

enum ExtraStyleTypes {
  minLengthPadding = 'minLengthPadding',
  append = 'append',
  prepend = 'prepend'
}

interface ExtraStyle {
  type: ExtraStyleTypes
}

interface MinLengthPaddingExtraStyle extends ExtraStyle {
  pad: string
  minLength: number
  sideToPad: LeftRight
}

interface AppendExtraStyle extends ExtraStyle {
  value: string
}

interface PrependExtraStyle extends ExtraStyle {
  value: string
}

interface TextDesign {
  attributeName?: string | string[]
  function?: string
  delimiter?: string
  as?: string
  maxLengthToConcatenate: number
  fitToWidth: boolean
  x: number
  y: number
  width: number
  height: number
  align: string
  verticalAlign: string
  fontSize: number
  fontFamily: string
  fontStyle: string
  multiLineAlign?: string
  multiLineFontSize?: number
  extraStyles: ExtraStyle[]
}

interface PageDesign {
  urlCodeAttributeNameForLink: string
  drawBorderAroundCanvas: boolean
}

interface Design {
  name: string
  shortName: string
  fileName: string
  backgroundImageURL: string
  width: number
  height: number
  textDesigns: TextDesign[]
  pageDesign: PageDesign
  resourceServer: string
}

export interface CertificationData {
  status: string
  directURLCode: string
  informations: Informations
  designs: Design[]
  duration?: string
  duration_unit?: string
}

interface CertificateCanvasProps {
  certData: CertificationData
  design: Design
}

const CertificateCanvas = forwardRef(({ certData, design }: CertificateCanvasProps, canvasRef: any): ReactElement => {
  const [image] = useImage(
    design.resourceServer + design.backgroundImageURL, 'anonymous', 'origin')
  const [fontAllLoaded, setFontAllLoaded] = useState<boolean>(false)
  const canvasId = useId()

  const drawCanvas = (waitFont: boolean): void => {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement
    const context = canvas.getContext('2d')

    if (context === null) {
      return
    }

    if (image === undefined) {
      return
    }

    context.drawImage(image, 0, 0, design.width, design.height)

    const fontPromisses: Array<Promise<any>> = []

    design.textDesigns.forEach((d: TextDesign, i: number) => {
      const texts = (() => {
        const date = new Date()

        switch (d.function) {
          case 'currentDate': {
            return [`${date.getFullYear()}`, `${date.getMonth() + 1}`, `${date.getDate()}`]
          }
          case 'currentDateYear': {
            return [`${date.getFullYear()}`]
          }
          case 'currentDateMonth': {
            return [`${date.getMonth() + 1}`]
          }
          case 'currentDateDay': {
            return [`${date.getDate()}`]
          }
        }

        const attributes = d.attributeName !== undefined
          ? Array.isArray(d.attributeName) ? d.attributeName : [d.attributeName]
          : []
        const texts = attributes.map(a => certData.informations[a])
        return texts
      })().map((t) => {
        const styledText = (d.extraStyles ?? []).reduce((textToStyle, style) => {
          switch (style.type) {
            case 'minLengthPadding': {
              const realStyle = style as MinLengthPaddingExtraStyle
              return textToStyle.padStart(realStyle.minLength, realStyle.pad)
            }
            case 'append': {
              return textToStyle + (style as AppendExtraStyle).value
            }
            case 'prepend': {
              return (style as PrependExtraStyle).value + textToStyle
            }
          }

          return textToStyle
        }, t)

        return styledText
      })

      const formattedTexts = (() => {
        if (d.as === 'date_yyyymmdd_ja') {
          // TODO: check array length
          return [`${texts[0]}`, '年', `${texts[1]}`, '月', `${texts[2]}`, '日']
        }

        return texts
      })()

      const concatenatedTexts = (() => {
        const delimiter = d.delimiter ?? ''
        const totalLength = formattedTexts.reduce((p: number, c: string) => p + [...(c + delimiter)].length, 0) - [...delimiter].length

        const maxLengthToConcatenate = Number.isFinite(d.maxLengthToConcatenate) ? d.maxLengthToConcatenate : 0
        const concatenatedTexts = (maxLengthToConcatenate > 0 && totalLength <= maxLengthToConcatenate)
          ? [formattedTexts.join(delimiter)]
          : formattedTexts
        return concatenatedTexts
      })()

      const fontSize = [...concatenatedTexts].length > 1
        ? d?.multiLineFontSize ?? d.fontSize
        : d.fontSize

      const fontConfig: Record<string, string> = {
        fontSize: `${fontSize}`,
        fontFamily: FONT_FAMILY_CONVERSION[d.fontFamily] ?? '"Sans Serif"',
        fontStyle: d.fontStyle
      }

      context.font = `${fontConfig.fontStyle} ${fontConfig.fontSize}px ${fontConfig.fontFamily}`

      const [styledTexts, styledWidths] = concatenatedTexts.map((t) => {
        const tempMetrix = context.measureText(t)
        return [t, tempMetrix.width] as [string, number]
      }).reduce((p: [string[], number[]], c: [string, number]):

      [string[], number[]] => [[...(p[0]), c[0]], [...(p[1]), c[1]]], [[], []])

      const maxWidth = Math.max(...styledWidths)
      const realFontSize = d.fitToWidth
        ? fontSize * Math.min(maxWidth + 2, d.width) / maxWidth
        : fontSize
      const align = [...styledTexts].length > 1
        ? d?.multiLineAlign ?? d.align
        : d.align

      if (waitFont) {
        const text = styledTexts.join('\n')
        const fontFamilies = [fontConfig.fontFamily.split(',')[0]]

        fontFamilies.forEach(ff => {
          const rawFontName = ff.replaceAll('"', '')
          const fontObs = new FontFaceObserver(rawFontName, { weight: `${fontConfig.fontStyle}` })
          fontPromisses.push(fontObs.load(text))
        })
      }

      context.font = `${fontConfig.fontStyle} ${realFontSize}px ${fontConfig.fontFamily}`
      // TODO: refactor very hacky baseline selection
      context.textBaseline = 'middle'
      const numLines = [...styledTexts].length
      const lineHeight = realFontSize

      styledTexts.filter((t) => [...t].length > 0).forEach((t, index) => {
        const lineAlign = align === 'justify' && [...t].length < 2
          ? 'left'
          : align

        // TODO: complete vertical align handling (middle, top...)
        const verticalAlign = d.height === undefined
          ? 'top'
          : d.verticalAlign
        const y = verticalAlign === 'bottom'
          ? d.y + (d.height ?? lineHeight) - lineHeight * (numLines - index - 0.5)
          : d.y + lineHeight * (index + 0.5)

        if (lineAlign !== 'justify') {
          context.textAlign = lineAlign as CanvasTextAlign
          const x = lineAlign === 'left'
            ? d.x
            : lineAlign === 'center'
              ? d.x + d.width / 2
              : d.x + d.width
          context?.fillText(t, x, y)
        } else {
          const justifyStart = d.x + context.measureText(t[0]).width / 2
          const justifyEnd = d.x + d.width - context.measureText(t.slice(-1)).width / 2
          const justifyStep = (justifyEnd - justifyStart) / ([...t].length - 1)
          context.textAlign = 'center'
          Array.from(t).forEach((c, cIndex) => {
            const cX = justifyStart + justifyStep * cIndex
            context.fillText(c, cX, y)
          })
        }

        // for design debuging
        /*
        context.beginPath()
        context.moveTo(d.x,d.y)
        context.lineTo(d.x + d.width, d.y)
        context.stroke()
        */
      })
    })

    if (waitFont) {
      Promise.all(fontPromisses)
        .then(() => {
          setFontAllLoaded(true)
        })
        .catch((err) => {
          throw err
        })
    } else {
      setFontAllLoaded(false)
    }
  }

  useEffect(() => {
    drawCanvas(true)
  }, [certData, image])

  useEffect(() => {
    if (!(fontAllLoaded as boolean)) {
      return
    }

    drawCanvas(false)
  }, [fontAllLoaded])

  const drawBorder = design.pageDesign.drawBorderAroundCanvas

  return (
    <>
      <div className='canvasContainer'>
        <canvas
          id={canvasId}
          width={design.width}
          height={design.height}
          style={drawBorder ? { border: 'solid 1px' } : {}}
          ref={canvasRef}
        />
      </div>
    </>
  )
})

interface CertificateBlockProps {
  certData: CertificationData
  design: Design
  modalControl: ModalControl
  showDownloadButton: boolean
}

const CertificateBlock = (
  { certData, design, modalControl, showDownloadButton }: CertificateBlockProps): ReactElement => {
  const canvasRef = useRef(null)
  const certificateCanvas = (
    <CertificateCanvas
      certData={certData}
      design={design}
      ref={canvasRef}
    />
  )

  // PDF download
  const fit = (dstW: number, dstH: number, srcW: number, srcH: number): [number, number] => {
    const rate = Math.min(dstW / srcW, dstH / srcH)
    return [rate * srcW, rate * srcH]
  }
  const onPDFDownloadButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (canvasRef.current === null) {
      return
    }
    const canvas = canvasRef.current as HTMLCanvasElement
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    const canvasDataURL = canvas.toDataURL()
    const doc = new JsPDF('p', 'mm', 'a4') // A4 portrait
    const pdfWidth = doc.internal.pageSize.getWidth() // mm
    const pdfHeight = doc.internal.pageSize.getHeight() // mm
    const margin = 0 // mm
    const maxWidthOnPDF = pdfWidth - margin * 2
    const maxHeightOnPDF = pdfHeight - margin * 2
    const [imageWidthOnPDF, imageHeightOnPDF] = fit(
      maxWidthOnPDF, maxHeightOnPDF, canvasWidth, canvasHeight)
    doc.addImage(canvasDataURL, 'image/png',
      margin, margin, imageWidthOnPDF, imageHeightOnPDF, undefined, 'FAST')
    doc.save(design.fileName, { returnPromise: true }).then(() => {
    })
  }

  return (
    <>
      <Row className='justify-content-md-center'>
        <Col lg='8'>
          {certificateCanvas}
        </Col>
      </Row>
      {
        showDownloadButton
          ? (
            <Row>
              <Col />
              <Col lg='6' className='text-center'>
                <Form className='d-grid gap-2 form-app'>
                  <Button
                    variant='primary' type='button'
                    className='text-center button-download-pdf-app'
                    onClick={onPDFDownloadButtonClick}
                  >
                    {design.name}のダウンロード
                  </Button>
                </Form>
              </Col>
              <Col />
            </Row>
            )
          : undefined
      }
    </>
  )
}

const CertificateBlocks = (
  { certData, showDownloadButton, showDirectURL }:
  {certData: CertificationData, showDownloadButton: boolean, showDirectURL: boolean}): ReactElement => {
  // modal dialog handling
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const handleClose = (): void => setModalShow(false)

  const modalControl = { show: setModalShow, message: setModalMessage }

  // direct URL DOM
  const directURLBase = `${window.location.origin.toString()}/public/`

  // copy button event handling
  // draw canvas per design
  const certificateBlocks = certData.designs.map((design, index) => {
    const urlCode = certData.informations[design.pageDesign.urlCodeAttributeNameForLink]

    const directURLTextRef = useRef<HTMLInputElement>(null)
    const onCopyButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
      if (directURLTextRef.current === null) {
        return
      }

      const textElement: HTMLInputElement = directURLTextRef.current

      if (!textElement.hasAttribute('value')) {
        return
      }

      const urlValue: string = textElement.getAttribute('value') ?? ''

      navigator.clipboard.writeText(urlValue)
        .then(() => {
          setModalMessage('URLをコピーしました')
          setModalShow(true)
        }, () => {
          setModalMessage('URLをコピーができませんでした')
          setModalShow(true)
        })
    }

    const directURLDiv = urlCode !== null
      ? (
        <Row className='justify-content-md-center'>
          <Col lg='8'>
            <p>
              ▼{design.name}のURLはこちら
            </p>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faLink} />
              </InputGroup.Text>
              <Form.Control
                type='text'
                defaultValue={`${directURLBase}${urlCode}`}
                readOnly
                ref={directURLTextRef}
              />
              <Button variant='primary' type='button' onClick={onCopyButtonClick}>
                コピー
              </Button>
            </InputGroup>
            <p>
              ※履歴書などに記載したい場合はこちらのURLをご使用ください。
            </p>
          </Col>
        </Row>
        )
      : undefined
    return (
      <React.Fragment key={index}>
        <CertificateBlock
          certData={certData}
          design={design}
          modalControl={modalControl}
          showDownloadButton={showDownloadButton}
        />
        {showDirectURL ? directURLDiv : undefined}
      </React.Fragment>
    )
  })

  return (
    <>
      {certificateBlocks}
      <MessageModal show={modalShow} message={modalMessage} onHide={handleClose} />
    </>
  )
}

export { CertificateBlocks }
