import { ReactElement } from 'react'

import {
  Button,
  Modal
} from 'react-bootstrap'

export interface ModalControl {
  show: (a: boolean) => void
  message: (a: string) => void
}

const MessageModal = ({ show, message, onHide }: {show: boolean, message: string, onHide: any}): ReactElement => {
  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          {message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={onHide}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MessageModal
