import React, { useState, ReactElement } from 'react'

import {
  Button,
  Col,
  Container,
  Form,
  Row,
  InputGroup
} from 'react-bootstrap'

import { CertificationData } from './CertificateCanvas'
import { fetchRemoesAPI } from './API'
import MessageModal from './MessageModal'

import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false
/* eslint-disable import/first */
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
/* eslint-enable import/first */

const timeUnitE2J = (u: string | undefined): string => {
  return {
    minutes: '分'
  }[u ?? ''] ?? u as string
}

const Refer = (): ReactElement => {
  // modal dialog handling
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const handleClose = (): void => setModalShow(false)

  const navigate = useNavigate()

  // states
  const [formData, setFormData] = useState({
    personalCode: '',
    examinationCode: ''
  })
  const [passwordHidden, setPasswordHidden]: [boolean, any] = useState<boolean>(true)

  const filterCodeText = (str: string): string => {
    return str.replace(/[^ -~]/g, '')
  }

  const onClickRefer = (e: any): void => {
    if (formData?.personalCode === '' || formData?.examinationCode === '') {
      setModalMessage('認証IDとパスワードを入力してください。')
      setModalShow(true)
      return
    }

    fetchRemoesAPI(
      formData.personalCode, formData.examinationCode, undefined)
      .then(async (response: Response) => await response.json())
      .then((data: CertificationData) => {
        if (data.status === 'ok') {
          navigate('/certificates/', { state: { certData: data } })
        } else if (data.status === 'not found') {
          setModalMessage('認証ID、もしくはパスワードの入力内容に誤りがございます。')
          setModalShow(true)
        } else if (data.status === 'access locked') {
          setModalMessage(`アカウントはロックされています。${data.duration ?? ''}${timeUnitE2J(data.duration_unit)}後に再度お試しください。`)
          setModalShow(true)
        } else if (data.status === 'birthday not registered') {
          setModalMessage('生年月日が登録されていないため表示できません。検定実施団体へご連絡ください。')
          setModalShow(true)
        } else {
          throw new Error('API response status is not ok')
        }
      })
      .catch((error: any) => {
        console.log(error)
        setModalMessage('認証ID、もしくはパスワードの入力内容に誤りがございます。')
        setModalShow(true)
      })
  }

  const onToggleButtonClicked = (e: any): void => {
    setPasswordHidden(!passwordHidden)
  }

  return (
    <>
      <div className='titleHeader'>
        <h2>デジタル合格証取得ページ</h2>
      </div>
      <Container className='mainContainer'>
        <Row className='justify-content-md-center'>
          <Col lg='8' className='text-center col-message'>
            合格、おめでとうございます。<br />
            本ページにてデジタル合格証の発行をしております。
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col lg='8'>
            <Form className='d-grid gap-2 form-app'>
              <Form.Group>
                <Form.Label />
                <Form.Text className='text-muted'>
                  ▼<span style={{ color: 'red' }}>「認証ID（半角）」</span>を入力してください。
                </Form.Text>
                <Form.Control
                  value={formData.personalCode}
                  onChange={e => {
                    e.target.value = filterCodeText(e.target.value)
                    setFormData({ ...formData, personalCode: e.target.value })
                  }}
                  onKeyDown={e => { if (e.keyCode === 13) { onClickRefer(e) } }}
                  type='text'
                  placeholder='認証ID（半角のみ）'
                />
              </Form.Group>
              <Form.Group>
                <Form.Label />
                <Form.Text className='text-muted'>
                  ▼<span style={{ color: 'red' }}>「パスワード（半角）」</span>を入力してください。
                </Form.Text>
                <InputGroup>
                  <Form.Control
                    value={formData.examinationCode}
                    onChange={e => {
                      e.target.value = filterCodeText(e.target.value)
                      setFormData({ ...formData, examinationCode: e.target.value })
                    }}
                    onKeyDown={e => { if (e.keyCode === 13) { onClickRefer(e) } }}
                    type={passwordHidden ? 'password' : 'text'}
                    placeholder='パスワード（半角のみ）'
                  />
                  <Button
                    variant='secondary'
                    onClick={onToggleButtonClicked}
                  >
                    <FontAwesomeIcon icon={passwordHidden ? faEyeSlash : faEye} />
                  </Button>
                </InputGroup>
              </Form.Group>
              <Button variant='primary' type='button' onClick={onClickRefer} className='text-center' size='lg'>
                取得
              </Button>
            </Form>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col lg='8' className='col-footnote-app'>
            ※デジタル合格証取得ページは以下の環境でご利用ください。<br />
            【パソコン】<br />
            macOS : Safari, Google Chrome （各最新版）<br />
            Windows : Microsoft Edge, Google Chrome （各最新版）<br />
            【スマートフォン】<br />
            iOS : Safari （最新版）<br />
            Android : Google Chrome （最新版）<br />
          </Col>
        </Row>
      </Container>
      <MessageModal show={modalShow} message={modalMessage} onHide={handleClose} />
    </>
  )
}

export default Refer
