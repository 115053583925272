import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import './App.css'

import Refer from './Refer.tsx'
import Direct from './Direct.tsx'
import Certificates from './Certificates.tsx'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [pathname])

  return null
}

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* form page to refer certificates */}
        <Route path='/' element={<Refer />} />
        <Route path='/index.html' element={<Refer />} />
        {/* result page of form page */}
        <Route path='/certificates/' element={<Certificates />} />
        {/* direct */}
        <Route path='/public/:directCode' element={<Direct />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
