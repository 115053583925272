const API_SERVER_MAP: {[key: string]: string} = {
  'http://localhost:3000': 'http://localhost:4000',
  'https://pwd-cer-snapshot.sigfoss.com': 'https://pwd-res-snapshot.sigfoss.com',
  'https://pwd-cer-staging.sigfoss.com': 'https://pwd-res-staging.sigfoss.com',
  'https://certificate.remoes.jp': 'https://remoes.jp'
}

const ENDPOINT_PATH = '/digital_certs'

export const fetchDummyAPI = async (
  personalCode: string | undefined,
  examinationCode: string | undefined,
  directCode: string | undefined): Promise<Response> => {
  if (personalCode === '111' && examinationCode === '111') {
    return await fetch('/111-111.json')
  } else if (personalCode === '222' && examinationCode === '222') {
    return await fetch('/222-222.json')
  } else if (directCode === '174e3d6f-52f0-44ff-92b7-e738c6c843ec') {
    return await fetch('/111-111.json')
  }

  return await fetch('fail://for.dummy.api/')
}

export const fetchRemoesAPI: any = async (
  personalCode: string | undefined,
  examinationCode: string | undefined,
  directCode: string | undefined) => {
  const apiServer = API_SERVER_MAP[window.location.origin.toString()]

  if (apiServer === undefined) {
    return await fetch('fail://for.dummy.api/')
  }

  const endpoint = apiServer + ENDPOINT_PATH

  if (personalCode !== undefined && examinationCode !== undefined) {
    return await fetch(
      `${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code1: personalCode, code2: examinationCode })
      }) // eslint-disable-line @typescript-eslint/restrict-template-expressions
  } else if (directCode !== undefined) {
    return await fetch(
      `${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ cert_code: directCode })
      }) // eslint-disable-line @typescript-eslint/restrict-template-expressions
  }

  return await fetch('fail://for.dummy.api/')
}
